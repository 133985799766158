import { LinkingOptions } from "@react-navigation/native";
import { RootStackParamList } from "./RootStack";

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [],
  config: {
    screens: {
      RootScreen: "/",
      Logout: "/logout",
      AuthCallback: "/auth-callback",
      TAC: "/tac",
      MyProperties: "/my-properties",
      Property: {
        path: "property/:propertyId",
        screens: {
          Overview: "/overview",
          PaymentsOwner: "/payments",
          Statements: "/statements",
          OffersHubOwner: "/explore"
        }
      },
      Tenancy: {
        path: "tenancy/:tenancyId",
        screens: {
          Overview: "/overview",
          Payments: "/payments",
          RequestList: "/requests-list",
          OffersHub: "/explore"
        }
      },
      Callback: {
        path: "callback",
        screens: {
          OfferHub: "/explore"
        }
      },
      Requests: {
        path: "tenancy/:tenancyId/requests",
        screens: {
          CreateRequest: "/create",
          GeneralRequest: "/general",
          UrgentRequest: "/urgent",
          VacateRequest: "/vacate",
          RequestDetails: "/:workflowId"
        }
      },
      ViewServiceDetail: {
        path: "view-service-detail",
        screens: {
          ViewServiceDetail: "/:tenancyId"
        }
      },
      PaymentSettings: {
        path: "payment-settings",
        screens: {
          Details: "/:tenancyId",
          AddPaymentMethod: "/add-payment-method"
        }
      },
      Statements: {
        path: "statements/:propertyId"
      },
      GroupStatements: {
        path: "group-statements/:propertyId"
      },
      UnderConstruction: "/under-construction",
      Welcome: "/welcome",
      NotFound: "*"
    }
  }
} as IntentionalAny<"Currently broken at the library level because of a change to TS">;
