import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, TouchableOpacity } from "@kolmeo/ui-core";
import coreThemeV2 from "../../theme/coreTheme";
import Icon from "../Icon";
import ButtonV2 from "../ButtonV2";
const MessageBar = ({ type, title, details, dismissAction, actionInfo, ...props }) => {
    const { palette, borderRadius, spacing } = coreThemeV2;
    const variants = {
        default: {
            iconType: "information",
            borderColor: palette.border.default,
            iconColor: palette.text.secondary,
            backgroundColor: palette.background.default,
            titleColor: palette.text.primary,
            detailsColor: palette.text.secondary,
            actionColor: palette.background.primary.default,
        },
        info: {
            iconType: "information",
            borderColor: palette.info.border,
            iconColor: palette.info.icon,
            backgroundColor: palette.info.background,
            titleColor: palette.info.text,
            detailsColor: palette.info.text,
            actionColor: palette.background.primary.default,
        },
        success: {
            iconType: "check-circle",
            borderColor: palette.success.border,
            iconColor: palette.success.icon,
            backgroundColor: palette.success.background,
            titleColor: palette.success.text,
            detailsColor: palette.success.text,
            actionColor: palette.background.primary.default,
        },
        warning: {
            iconType: "information",
            borderColor: palette.warning.border,
            iconColor: palette.warning.icon,
            backgroundColor: palette.warning.background,
            titleColor: palette.warning.text,
            detailsColor: palette.warning.text,
            actionColor: palette.background.primary.default,
        },
        danger: {
            iconType: "close-circle",
            borderColor: palette.danger.border,
            iconColor: palette.danger.icon,
            backgroundColor: palette.danger.background,
            titleColor: palette.danger.text,
            detailsColor: palette.danger.text,
            actionColor: palette.background.destructive.default,
        },
    };
    return (_jsxs(Box, { backgroundColor: variants[type].backgroundColor, borderRadius: borderRadius.br8, borderWidth: 1, borderColor: variants[type].borderColor, padding: spacing.sp16, flexDirection: "row", alignItems: "flex-start", justifyContent: "center", ...props, children: [_jsx(Box, { marginRight: spacing.sp16, children: _jsx(Icon, { type: variants[type].iconType, size: "sm", color: variants[type].iconColor }) }), _jsxs(Box, { flexDirection: "column", alignItems: "flex-start", flex: 1, children: [title ? (_jsx(Text, { variant: "textHeavy14", color: variants[type].titleColor, children: title })) : null, details ? (_jsx(Text, { variant: "textMedium14", color: variants[type].detailsColor, marginTop: `${title ? spacing.sp8 : 0}px`, children: details })) : null, actionInfo ? (actionInfo.customButtonProps ? (_jsx(Box, { marginTop: spacing.sp16, children: _jsx(ButtonV2, { ...actionInfo.customButtonProps, label: actionInfo.title, onPress: actionInfo.onPress, textProps: { variant: "textHeavy14" }, dataUsageId: actionInfo?.dataUsageId }) })) : (_jsx(TouchableOpacity, { marginTop: spacing.sp16, onPress: actionInfo.onPress, children: _jsx(Text, { color: variants[type].actionColor, variant: "textHeavy14", children: actionInfo.title }) }))) : null] }), dismissAction ? (_jsx(TouchableOpacity, { onPress: dismissAction, height: "100%", justifyContent: "center", alignItems: "center", children: _jsx(Icon, { type: "close", color: variants[type].actionColor, size: "xs" }) })) : null] }));
};
export default MessageBar;
