import { useEffect, useMemo } from "react";

import { createMaterialTopTabNavigator, MaterialTopTabScreenProps, MaterialTopTabBarProps } from "@react-navigation/material-top-tabs";

import { Icon, Loading } from "@kolmeo/ui-components";
import { PropertyOverview, Statements, OwnerPayments, TenancyOffersHub } from "@src/screens";
import { PropertyParamList } from "@src/navigation/RootStack";
import { CustomTopBar } from "@src/components";
import { PropertyContext } from "./useProperty";
import { variableAlwaysNumber } from "@src/utils/textTransformers/variableAlwaysNumber";
import { useBranding, useGetBrandingObjectForOwner } from "../useBranding";
import { useOwnerFeatureFlags } from "@src/screens/Owner/hooks/useOwnerFeatureFlags";
import { featureList, useFeature, useUpdateFeatureFlags } from "@src/lib/FeatureFlags";
import { useTranslation } from "react-i18next";

export type PropertyTabsParamsList = {
  Overview: { propertyId: number };
  Transactions: { propertyId: number };
  Statements: { propertyId: number };
  PaymentsOwner: { propertyId: number };
  OffersHubOwner: { propertyId?: number };
};

const PropertyScreens = createMaterialTopTabNavigator<PropertyTabsParamsList>();

export type OverviewProps = MaterialTopTabScreenProps<PropertyTabsParamsList, "Overview">;
export type TransactionsProps = MaterialTopTabScreenProps<PropertyTabsParamsList, "Transactions">;
export type PaymentsOwner = MaterialTopTabScreenProps<PropertyTabsParamsList, "PaymentsOwner">;
export type StatementsProps = MaterialTopTabScreenProps<PropertyTabsParamsList, "Statements">;
export type OffersHubProps = MaterialTopTabScreenProps<PropertyTabsParamsList, "OffersHubOwner">;

const PropertyNavigator = (params: PropertyParamList): JSX.Element => {
  const { propertyId } = params.route.params;
  const { data: brandingData } = useGetBrandingObjectForOwner(variableAlwaysNumber(propertyId));
  const { setBrandingObject } = useBranding();
  const { t } = useTranslation("Tenant");
  const updateFeatureFlag = useUpdateFeatureFlags();
  const { data: featureFlags } = useOwnerFeatureFlags(propertyId);
  const showKolmeoOwnerPlusExploreTab = useFeature(featureList.showKolmeoOwnerPlusExploreTab);
  const showOfferIsActive = useFeature(featureList.showOffers);

  const contextValue = useMemo(() => {
    return {
      ...params,
      brandingObject: brandingData?.branding.businessBrandId ? brandingData?.branding : undefined,
      propertyEntityId: brandingData?.propertyEntityId,
      isIndividualProperty: true
    };
  }, [params, brandingData]);

  useEffect(() => {
    // get branding data
    if (brandingData && propertyId) {
      setBrandingObject(brandingData.branding);
    }
  }, [propertyId, brandingData, setBrandingObject]);

  useEffect(() => {
    if (featureFlags) {
      updateFeatureFlag(featureFlags);
    }
  }, [featureFlags, updateFeatureFlag]);

  return (
    <PropertyContext.Provider value={contextValue}>
      <PropertyScreens.Navigator
        tabBar={(props: MaterialTopTabBarProps) => <CustomTopBar {...props} indicatorColour={contextValue.brandingObject?.primaryColor} />}
      >
        <PropertyScreens.Screen
          name="Overview"
          component={PropertyOverview}
          options={{
            tabBarLabel: "Overview",
            tabBarIcon: ({ color, focused }) => <Icon type={focused ? "property-active" : "property-default"} size={"sm"} color={color} />,
            lazy: true,
            lazyPlaceholder: () => <Loading />
          }}
        />
        <PropertyScreens.Screen
          name="PaymentsOwner"
          component={OwnerPayments}
          options={{
            tabBarLabel: "Payments",
            tabBarIcon: ({ color, focused }) => <Icon type={focused ? "payments-active" : "payments-default"} size={"sm"} color={color} />,
            lazy: true,
            lazyPlaceholder: () => <Loading />
          }}
        />
        <PropertyScreens.Screen
          name="Statements"
          component={Statements}
          options={{
            tabBarLabel: "Statements",
            tabBarIcon: ({ color, focused }) => (
              <Icon type={focused ? "statements-active" : "statements-default"} size={"sm"} color={color} />
            ),
            lazy: true,
            lazyPlaceholder: () => <Loading />
          }}
        />
        {(showOfferIsActive?.enabled ?? true) && showKolmeoOwnerPlusExploreTab?.enabled && (
          <PropertyScreens.Screen
            name="OffersHubOwner"
            component={TenancyOffersHub}
            options={{
              tabBarTestID: "owner-explore-tab",
              title: "OffersHubOwner",
              tabBarLabel: t("Overview.OffersHub"),
              tabBarIcon: ({ color, focused }) => <Icon type={focused ? "star" : "star-default"} size={"sm"} color={color} />,
              lazy: true,
              lazyPlaceholder: () => <Loading />
            }}
          />
        )}
      </PropertyScreens.Navigator>
    </PropertyContext.Provider>
  );
};

export default PropertyNavigator;
