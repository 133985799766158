import { Card } from "@kolmeo/ui-components";
import { CardProps } from "@kolmeo/ui-components/dist/packages/ui-components/src/components/Card";
import { Link } from "@react-navigation/native";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import { RootStackParamList } from "@src/navigation/RootStack";
import { RequestsStackParamsList } from "@src/navigation/TenancyNavigator/RequestsNavigator";

const LinkCard = ({
  children,
  to,
  cardProps,
  isPressable
}: {
  to: To<RequestsStackParamsList & RootStackParamList>;
  cardProps?: Omit<CardProps, "children">;
  isPressable?: boolean;
} & React.PropsWithChildren) => {
  const disabledProps = isPressable ? {} : { opacity: 0.6 };
  const linkProps = isPressable
    ? { to: to }
    : {
        to: to,
        disabled: true,
        onPress: (e: { preventDefault: () => void }) => {
          e.preventDefault();
          return false;
        }
      };
  return (
    <Card flex={1} isPressable={isPressable} {...cardProps} marginBottom={6} {...disabledProps}>
      <Link {...linkProps} style={{ height: "100%" }}>
        {children}
      </Link>
    </Card>
  );
};

export default LinkCard;
