import { Icon, ButtonV2 } from "@kolmeo/ui-components";
import { Box, SectionList, SectionListProps, Text, TouchableOpacity, useTheme } from "@kolmeo/ui-core";

import type { ThemeBrandingObject } from "@src/lib/Kiitos";

import { Seperator } from "@src/components";
import { ScreenEmptyState } from "@src/containers";
import { SectionListRenderItemInfo } from "react-native";

import { PaymentsHistoryResponse } from "@src/types";

import { PaymentAmount } from "@src/components/PaymentDetails/components";
import { useTranslation } from "react-i18next";

const SELECTED_HIGHLIGHT = "#daedf5";

const PaymentsList = ({
  sections,
  isFilterActive = false,
  setActiveItem,
  activeItem,
  brandingObject,
  renderItem: RenderItem,
  loadMoreHandler,
  ...props
}: {
  activeItem?: PaymentsHistoryResponse;
  setActiveItem: React.Dispatch<React.SetStateAction<PaymentsHistoryResponse | undefined>>;
  isFilterActive: boolean;
  brandingObject?: ThemeBrandingObject;
  loadMoreHandler?: () => void;
} & SectionListProps<PaymentsHistoryResponse>) => {
  const { palette, spacing, colors } = useTheme();
  const { t } = useTranslation(["Tenant"]);

  const wrapRenderItem = (listItem: SectionListRenderItemInfo<PaymentsHistoryResponse>) => {
    const { item, index } = listItem;
    return RenderItem ? (
      <TouchableOpacity
        data-usage-id="payments-rent-payments-tile-click-link"
        data-usage-action={index.toString()}
        onPress={() => setActiveItem(listItem.item)}
      >
        <Box
          flexDirection="row"
          alignItems="center"
          backgroundColor={
            item.paymentFlowId === activeItem?.paymentFlowId &&
            item.isPayableByIncome === activeItem?.isPayableByIncome &&
            item.transactionActionCode === activeItem?.transactionActionCode &&
            item.transactionId === activeItem.transactionId
              ? SELECTED_HIGHLIGHT
              : undefined
          }
        >
          <Box
            minHeight={76}
            flex={1}
            paddingLeft={spacing.px20}
            paddingRight={spacing.px16}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <RenderItem {...listItem} />
            <PaymentAmount amount={item.amount} />
          </Box>
          <Box marginRight={spacing.px8}>
            <Icon type="chevron-right" size="lg" />
          </Box>
        </Box>
      </TouchableOpacity>
    ) : null;
  };

  return (
    <SectionList
      {...props}
      contentContainerStyle={{ flexGrow: 1 }}
      sections={sections}
      keyExtractor={(item, index) => item.paymentFlowId + index}
      ItemSeparatorComponent={() => <Seperator seperatorColor={palette.border.default} />}
      renderItem={wrapRenderItem}
      renderSectionHeader={({ section: { title } }) => (
        <Box backgroundColor={palette.border.light} justifyContent="center" alignItems="center" height={40}>
          <Text variant="label12SemiBold">{title}</Text>
        </Box>
      )}
      ListFooterComponent={
        loadMoreHandler ? (
          <Box justifyContent="center" alignItems="center" paddingY={spacing.px8}>
            <ButtonV2 variant="textButton" label="load More" onPress={() => loadMoreHandler()} />
          </Box>
        ) : null
      }
      ListEmptyComponent={
        <ScreenEmptyState
          setMaxHeight
          textColor={brandingObject?.primaryColor || colors.primary}
          {...(isFilterActive
            ? {
                title: t("Tenant:Payments.PaymentsEmptyFilterActive")
              }
            : {
                title: t("Tenant:Payments.PaymentsEmpty")
              })}
        />
      }
    />
  );
};

export default PaymentsList;
