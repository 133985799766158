import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Text, Pressable } from "@kolmeo/ui-core";
import Icon from "../../Icon";
import { kebabCase } from "lodash";
const PressableMenuOption = ({ option, onDismiss, primaryColor }) => {
    return (_jsxs(_Fragment, { children: [_jsx(Text, { variant: "paragraph14", color: option.textColor ?? primaryColor, children: option.headerTitle }), _jsxs(Pressable, { onPress: () => {
                    option.onPress ? option.onPress() : null;
                    onDismiss();
                }, disabled: option.disabled ?? false, testID: `pop-up-menu-option-${kebabCase(option.title)}`, "data-usage-id": option.dataUsageId, hitSlop: { top: 15, bottom: 15 }, flexDirection: "row", alignItems: "center", justifyContent: "space-between", opacity: option.disabled ? 0.5 : 1, children: [_jsxs(Box, { flex: 1, flexDirection: "column", children: [_jsx(Text, { fontWeight: option.titleBold ? "bold" : "normal", variant: option.titleBold ? "label12SemiBold" : "paragraph12", color: option.textColor ?? primaryColor, children: option.title }), _jsx(Text, { fontWeight: option.secondaryTitleBold ? "bold" : "normal", variant: option.titleBold ? "label12SemiBold" : "paragraph12", color: option.textColor ?? primaryColor, children: option.secondaryTitle })] }), option.iconType ? _jsx(Icon, { type: option.iconType, color: option.textColor ?? primaryColor, size: "md" }) : null] })] }));
};
export default PressableMenuOption;
