import { createStackNavigator } from "@react-navigation/stack";
import UrgentRequest from "@src/screens/Tenant/TenancyRequests/UrgentRequest";
import CreateRequest from "@src/screens/Tenant/TenancyRequests/CreateRequest";
import RequestDetails from "@src/screens/Tenant/TenancyRequests/RequestDetails";
import { useTranslation } from "react-i18next";
import { NavigatorScreenParams, useRoute } from "@react-navigation/core";
import { TenantTabsParamsList } from "../TenancyNavigator/TenantTabScreens";
import GeneralRequest from "@src/screens/Tenant/TenancyRequests/GeneralRequest";
import { useEffect, useMemo } from "react";
import { RequestsParamList } from "../RootStack";
import { RequestContext } from "./useRequests";
import { useGlobalTenant } from "../hooks/useGlobalTenant";
import VacateRequest from "@src/screens/Tenant/TenancyRequests/VacateRequest";

export type RequestsStackParamsList = {
  Root: undefined;
  Tenancy: NavigatorScreenParams<TenantTabsParamsList>;
  CreateRequest: { tenancyId?: number };
  UrgentRequest: undefined;
  GeneralRequest: { tenancyId?: number };
  VacateRequest: { tenancyId?: number };
  RequestDetails: { workflowId: number; tenancyId: number };
};

const RequestsStack = createStackNavigator<RequestsStackParamsList>();
export const RequestsNavigator = (paramList: RequestsParamList) => {
  const { tenancyId } = paramList.route.params;
  const nav = useRoute();
  const { handleSetTenancyId } = useGlobalTenant();
  const { t } = useTranslation("Tenant", { keyPrefix: "Requests" });
  // the first tenancyId prop is retrieved when using react-native navigation's navigate prop.
  // the second prop is used during deep linking

  useEffect(() => {
    let _tenancyId;
    if (tenancyId) {
      _tenancyId = tenancyId;
    } else {
      //@ts-ignore - how do we type dynamic params (second params property)?
      _tenancyId = nav.params?.params.tenancyId;
    }
    if (_tenancyId) {
      handleSetTenancyId(_tenancyId);
    }
  }, []);

  const contextValue = useMemo(() => {
    return {
      ...paramList,
      //@ts-ignore - how do we type dynamic params (second params property)?
      tenancyId: tenancyId ? tenancyId : nav.params?.params.tenancyId
    };
  }, [paramList]);

  return (
    <RequestContext.Provider value={contextValue}>
      <RequestsStack.Navigator screenOptions={{ headerShown: false }}>
        <RequestsStack.Screen name="CreateRequest" component={CreateRequest} options={{ title: t("NewRequest") }} />
        <RequestsStack.Screen name="GeneralRequest" component={GeneralRequest} options={{ title: t("RequestFormHeading") }} />
        <RequestsStack.Screen name="VacateRequest" component={VacateRequest} options={{ title: t("VacateRequestFormHeading") }} />
        <RequestsStack.Screen name="UrgentRequest" component={UrgentRequest} options={{ title: t("UrgentRequestHeading") }} />
        <RequestsStack.Screen name="RequestDetails" component={RequestDetails} options={{ title: t("RequestDetailsHeading") }} />
      </RequestsStack.Navigator>
    </RequestContext.Provider>
  );
};
