export default {
  LeaseTileTitle: "Lease",
  FixedTermLabel: "Fixed term lease: {{content}}",
  PeriodicLabel: "Month-to-month lease from {{content}}",
  DaysRemainingLabel: "{{daysCalc}} days remaining.",
  LeaseStatus: {
    Ended: "Ended",
    Signed: "Signed",
    Pending: "Pending",
    Current: "Current",
    Default: "Unknown"
  },
  TenancyTitle: {
    CurrentTenancy: "Current tenancy",
    UpcomingTenancy: "Upcoming tenancy",
    PastTenancy: "Past tenancy",
    Default: "Property"
  },
  MoveInDate: "Move in date",
  VacateDate: "Vacate date",
  PlanningToMoveLabel: "Planning to move?",
  VacatedRequestTitle: "Vacate Request Submitted.",
  PlanningToMoveDescription: "We’ve made things super easy. Lodge your request to vacate and your property manager will be in touch.",
  RequestToVacateButton: "Request to Vacate Property",
  VacatedRequestLabel: "You currently have an active Vacate Request. Once it's finalised, you will be able to submit a new one.",
  ExploreButtonLabel: "Explore Move-out Offers"
};
