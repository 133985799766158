import { Text, TouchableOpacity, useTheme } from "@kolmeo/ui-core";
import { addAlphaToHex } from "@src/lib/Kiitos";

const SelectableChip = ({
  label,
  onPress,
  isActive = false,
  color,
  dataUsageId
}: {
  label: string;
  onPress: () => void;
  isActive?: boolean;
  color?: string;
  dataUsageId?: string;
}) => {
  const { spacing, palette } = useTheme();
  const HEIGHT = 42;

  return (
    <TouchableOpacity
      data-usage-id={dataUsageId}
      onPress={onPress}
      width="calc(50% - 10px)"
      borderWidth={2}
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      height={HEIGHT}
      borderRadius={HEIGHT / 2}
      marginBottom={spacing.px16}
      backgroundColor={isActive ? addAlphaToHex(color ?? palette.border.default) : "transparent"}
      borderColor={isActive ? color : palette.border.default}
    >
      <Text color={isActive ? color : "#0000008C"}>{label}</Text>
      {/* {isActive && <Icon type="caret-right" size="xs" color={colors.primary} />} */}
    </TouchableOpacity>
  );
};

export default SelectableChip;
